import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const SkeletonUserDetailMembershipPage = () => {
  return (
    <div className="row" style={{ padding: 40 }}>
      <Grid item>
        <Grid container direction="column">
          <Grid item className="container-membership parent">
            <Grid container direction="column">
              <Grid item>
                <Skeleton
                  variant="rect"
                  width="100%"
                  height={30}
                  animation="wave"
                  className="skeletonRounded"
                />
              </Grid>
              <Grid item>
                <div className="container-progress-bar">
                  <Skeleton
                    variant="rect"
                    width="100%"
                    height={20}
                    animation="wave"
                    className="skeletonRounded"
                  />
                </div>
              </Grid>
              <Grid item>
                <Skeleton
                  variant="rect"
                  width="100%"
                  height={20}
                  animation="wave"
                  className="skeletonRounded"
                />
              </Grid>
              <Grid item>
                <Grid container direction="row" justify="space-between">
                  <Grid item>
                    <Skeleton
                      variant="rect"
                      width="100%"
                      height={40}
                      animation="wave"
                      className="skeletonRounded"
                    />
                  </Grid>
                  <Grid item>
                    <Skeleton
                      variant="rect"
                      width="100%"
                      height={40}
                      animation="wave"
                      className="skeletonRounded"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="row" className="scroll-container container-bundle">
              <Grid item lg={4} md={4} style={{ padding: 6 }}>
                <Skeleton
                  variant="rect"
                  width="100%"
                  height={140}
                  animation="wave"
                  className="skeletonRounded"
                />
              </Grid>
              <Grid item lg={4} md={4} style={{ padding: 6 }}>
                <Skeleton
                  variant="rect"
                  width="100%"
                  height={140}
                  animation="wave"
                  className="skeletonRounded"
                />
              </Grid>
              <Grid item lg={4} md={4} style={{ padding: 6 }}>
                <Skeleton
                  variant="rect"
                  width="100%"
                  height={140}
                  animation="wave"
                  className="skeletonRounded"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default SkeletonUserDetailMembershipPage;
