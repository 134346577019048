/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
// style
import './ButtonTextUnderlineStyle.scss';
// import { stylesJs } from "./ButtonTextUnderlineStyle";

const ButtonTextUnderline = props => {
  const { labelText, onClick } = props;

  return (
    <div className="primary-text-button-container">
      <label className="primary-text-button fontSize12 no-select" onClick={onClick}>
        {labelText}
      </label>
    </div>
  );
};

ButtonTextUnderline.propTypes = {
  labelText: PropTypes.string,
  onClick: PropTypes.func,
};

export default ButtonTextUnderline;
