import React from 'react';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const SkeletonOrderShipmentPage = () => {
  return (
    <div className="row" style={{ padding: 40 }}>
      <Grid container spacing={3} direction="column" className="flex-wrap-unset">
        <Grid item lg md className="section-page-header">
          <Grid container justify="space-between">
            <Grid item lg={8} md={8}>
              <Skeleton
                variant="rect"
                width="100%"
                height={40}
                animation="wave"
                className="skeletonRounded"
              />
            </Grid>
            <Grid item lg={3} md={3}>
              <Grid container justify="flex-end" alignItems="center">
                <Skeleton
                  variant="rect"
                  width="100%"
                  height={40}
                  animation="wave"
                  className="skeletonRounded"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg md className="section-table-body">
          <Skeleton
            variant="rect"
            width="98%"
            height={360}
            animation="wave"
            className="skeletonRounded"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default SkeletonOrderShipmentPage;
